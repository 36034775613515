import React from 'react';
import Link from 'gatsby-link';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
// import acousticDuo from '../assets/images/acoustic-duo/acoustic-duo-profile.jpg';

const AcousticBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      AcousticDuo: file(relativePath: { eq: "acoustic-duo/acoustic-duo-profile.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <section id="banner" className="major" style={{ marginBottom: '30px' }}>
      <div className="inner">
        <header className="major">
          <h1>Acoustic Duo</h1>
        </header>
        <div className="row">
          <div className="6u 12u$(small)">
            <p>
              Consisting of frontman Danny and lead guitarist Steve performing relaxed renditions of
              a range of classics spanning all eras, the Audio Vultures Acoustic Duo is perfect for
              smaller venues, more low-key occasions and afternoon wedding reception music to
              entertain your guests.
            </p>
            <p>
              {' '}
              <Link to="/acoustic-duo">Read more about the Audio Vultures Acoustic Duo.</Link>
            </p>
            <iframe
              width="100%"
              height="270"
              scrolling="no"
              frameBorder="no"
              title="Audio Vultures Acoustic Duo"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1050371758&color=%23ff9900&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>{' '}
          </div>
          <div className="6u 12u$(small)">
            <span className="acoustic-image">
              <Img
                fluid={data.AcousticDuo.childImageSharp.fluid}
                alt="Audio Vultures Acoustic Duo"
                // style={{ width: '25%', backgroundSize: 'cover', display: 'inline-block' }}
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default AcousticBanner;
